<template>
  <div class="refresh-list">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },

    resData: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    resData(newV) {
      this.loadedEnd(newV);
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      pageNum: 1,
      pageSize: 10,
      copyList: []
    };
  },
  methods: {
    loadedEnd(data) {
      data.rows.forEach(item => {
        this.copyList.push(item);
      });
      this.$emit("update:list", this.copyList);
      this.total = data.total;

      this.pageNum++;
      this.loading = false;

      if (this.copyList.length >= this.total) {
        this.finished = true;
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.copyList = [];
        this.$emit("update:list", this.copyList);
        this.pageNum = 1;
        this.refreshing = false;
      }

      this.$emit("onLoad", this.pageNum);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    }
  }
};
</script>

<style lang="less" scoped>
.refresh-list {
  width: calc(100% + 40px);
  height: 100%;
  padding: 0 20px;
  transform: translateX(-20px);
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
