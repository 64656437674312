<template>
  <div class="message-wrapper">
    <title-bar :title="'消息通知'"></title-bar>
    <div class="message-content">
      <div class="message-read-box">
        <div
          class="message-read"
          :class="{
            disabled: showReadAllBtn
          }"
          @click="onReadAll"
        >
          全部已读
        </div>
      </div>
      <div class="message-list-box">
        <refresh-list
          :resData="resData"
          :list.sync="messageList"
          @onLoad="onLoad"
        >
          <div class="message-list">
            <div
              class="message-item"
              v-for="(item, index) in messageList"
              :key="index"
              @click="onRead(item)"
            >
              <div class="item-tag" v-if="!item.isRead"></div>
              <div class="item-date">{{ item.createTime | dateFilter }}</div>
              <div class="item-msg">
                {{ item.content }}
              </div>
            </div>
          </div>
        </refresh-list>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import RefreshList from "../components/RefreshList";
const READ = 1; // 已读为1 未读为0

export default {
  components: {
    RefreshList
  },

  data: () => ({
    resData: {}, // 接口返回数据
    messageList: [] // 消息列表
  }),
  methods: {
    onClickSelfIcon() {
      this.showSetting = true;
    },

    async onLoad(pageNum = 1) {
      let { data } = await this.$api.getUserMsg(pageNum);
      console.log(data);

      this.resData = {
        rows: data.rows,
        total: data.total
      };
    },

    onRead(item) {
      if (item.isRead) {
        return;
      }

      this.$api.updateUserMsg(item.id).then(() => {
        item.isRead = READ;
      });
    },

    onReadAll() {
      let str = "";
      this.messageList
        .filter(item => !item.isRead)
        .forEach(item => {
          str += item.id + ",";
          item.isRead = READ;
        });

      this.$api.updateUserMsg(str);
    }
  },
  computed: {
    showReadAllBtn() {
      return this.messageList.filter(item => !item.isRead).length < 1;
    }
  },
  filters: {
    dateFilter(date) {
      let newDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      return newDate;
    }
  }
};
</script>

<style lang="less" scoped>
.message-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
}

.message-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.message-read-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 24px;

  .message-read {
    padding: 2px 16px;
    background-color: #fff;
    border: 1px solid #003399;
    border-radius: 8px;
    color: #003399;

    &.disabled {
      color: #999;
      border-color: #999;
      pointer-events: none;
    }
  }
}

.message-list-box {
  width: 100%;
  flex: 1;
  overflow: hidden;
}

.message-list {
  width: 100%;
  height: 100%;

  padding: 0 12px;
}

.message-item {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 12px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 2px 4px #888888;
  position: relative;

  div {
    font-size: 12px;
  }

  .item-tag {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff0000;

    position: absolute;
    right: 16px;
    top: 12px;
  }

  .item-date {
    margin-bottom: 8px;
  }
}
</style>
